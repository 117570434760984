import React from 'react';
import styled from 'styled-components';

import { ORANGE } from '../constants/style';

const StyledTile = styled.div`
  div {
    margin-bottom: 1em;
  }
  .text {
    text-align: justify;
  }
  .icon {
    color: ${ORANGE};
    font-size: 3.2em;
  }
`;

const Tile = ({ label, icon }) => {
  return (
    <StyledTile className="offer-tile">
      <div className="container icon is-primary">
        <p className={icon} />
      </div>
      <div className="container label">
        <p className="title is-5 is-family-secondary">{label}</p>
      </div>
      {/**
        <div className="container text">
          <p>{text}</p>
        </div>
      */}
    </StyledTile>
  );
};

export default Tile;
