import React from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';

const StyledSubtitle = styled.h3`
  margin-top: 2.5em !important;
`;

const Intro = () => {
  return (
    <section className="section is-small" id="Home">
      <div className="container is-centered has-text-centered">
        <h1 className="title is-family-secondary">
          <FormattedMessage id="Intro.title" />
        </h1>
        <StyledSubtitle className="subtitle has-text-justified">
          <FormattedMessage id="Intro.subtitle" />
        </StyledSubtitle>
      </div>
    </section>
  );
};

export default Intro;
