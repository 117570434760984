import React from 'react';
import styled from 'styled-components';

import { BLUE } from '../constants/style';
import Button from './Button';

const StyledCard = styled.div`
  height: 33em;
  max-width: 23em;
  border: 6px solid ${BLUE};
  box-sizing: border-box;
  box-shadow: -4px 8px 8px rgba(0, 0, 0, 0.25);
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  display: flex;
  flex-direction: column;
  margin: 0 auto 2em auto;
`;

const CardHeader = styled.div`
  width: 100%;
  height: 8em;
  background: ${BLUE};
  display: flex;
  flex-direction: column;
  padding: 0.5em;
  h5:first-of-type {
    margin-top: auto;
  }
`;

const CardContent = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 1.4em 1em 1em 1em;
  font-size: 0.9em;
`;

const StyledButton = styled(Button)`
  margin-top: auto;
  width: 10em;
  align-self: center;
`;

const OfferCard = ({ children, title, priceForOne, priceForAbo }) => {
  return (
    <StyledCard>
      <CardHeader>
        <h3 className="is-size-4 has-text-centered has-text-weight-bold has-text-white">{title}</h3>
        <h5 className="is-size-6 has-text-centered has-text-weight-bold has-text-white">{priceForOne}</h5>
        <h5 className="is-size-6 has-text-centered has-text-weight-bold has-text-white">{priceForAbo}</h5>
      </CardHeader>
      <CardContent>
        {children}
        <StyledButton className="button is-primary is-outlined is-medium is-radiusless is-family-secondary is-uppercase">
          Book Now
        </StyledButton>
      </CardContent>
    </StyledCard>
  );
};

export default OfferCard;
