import React from 'react';
import { FormattedMessage } from 'react-intl';

import OfferCard from '../elements/OfferCard';
import OfferCardItem from '../elements/OfferCardItem';
import Title from '../elements/Title';

const OfferCards = () => {
  return (
    <>
      <section id="Offers" className="section is-small">
        <h1 className="is-size-3 has-text-left shifted">
          <Title>
            <FormattedMessage id="Offer.title" />
          </Title>
        </h1>
      </section>
      <section className="section">
        <div className="container">
          <div className="columns">
            <div className="column">
              <OfferCard
                title={<FormattedMessage id="Offer.card.single.title" />}
                priceForOne={<FormattedMessage id="Offer.card.single.price" />}
                priceForAbo={<FormattedMessage id="Offer.card.single.abo.price" />}
              >
                <OfferCardItem text={<FormattedMessage id="Offer.card.single.item.1" />} />
                <OfferCardItem text={<FormattedMessage id="Offer.card.single.item.2" />} />
                <OfferCardItem text={<FormattedMessage id="Offer.card.single.item.3" />} />
                <OfferCardItem text={<FormattedMessage id="Offer.card.single.item.4" />} />
                <OfferCardItem text={<FormattedMessage id="Offer.card.single.item.5" />} />
                <OfferCardItem text={<FormattedMessage id="Offer.card.single.item.6" />} />
                <OfferCardItem text={<FormattedMessage id="Offer.card.single.item.7" />} />
              </OfferCard>
            </div>
            <div className="column">
              <OfferCard
                title={<FormattedMessage id="Offer.card.group.title" />}
                priceForOne={<FormattedMessage id="Offer.card.group.price" />}
                priceForAbo={<FormattedMessage id="Offer.card.group.abo.price" />}
              >
                <OfferCardItem text={<FormattedMessage id="Offer.card.group.item.1" />} />
                <OfferCardItem text={<FormattedMessage id="Offer.card.group.item.2" />} />
                <OfferCardItem text={<FormattedMessage id="Offer.card.group.item.3" />} />
                <OfferCardItem text={<FormattedMessage id="Offer.card.group.item.4" />} />
                <OfferCardItem text={<FormattedMessage id="Offer.card.group.item.5" />} />
                <OfferCardItem text={<FormattedMessage id="Offer.card.group.item.6" />} />
                <OfferCardItem text={<FormattedMessage id="Offer.card.group.item.7" />} />
                <OfferCardItem text={<FormattedMessage id="Offer.card.group.item.8" />} />
              </OfferCard>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default OfferCards;
