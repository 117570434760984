import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"
import React, { Fragment } from "react"
import { FormattedMessage } from "react-intl"
import Slider from "react-slick"
import styled from "styled-components"

import Title from "../elements/Title"
import Quotation from "./Quotation"

const StyledContainer = styled.div`
  max-width: 50em;
  h1 {
    margin-bottom: 1em;
  }
`

export const TestimonialsTemplate = ({ gleb, olga }) => {
  const settings = {
    dots: true,
    autoplay: true,
    arrows: false,
    infinite: true,
    speed: 800,
    autoplaySpeed: 7000,
    slidesToShow: 1,
    slidesToScroll: 1,
  }
  return (
    <Fragment>
      <section className="section is-small" id="Testimonials">
        <h1 className="is-size-3 has-text-left shifted">
          <Title>
            <FormattedMessage id="Testimonials.title" />
          </Title>
        </h1>
      </section>
      <section className="section is-small">
        <StyledContainer className="container is-centered has-text-centered">
          <Slider {...settings}>
            <Quotation
              img={undefined}
              name="Melanie"
              text="Ich trainiere seit ein paar Wochen mit Ilya und die Trainings sind sehr gut geplant. Er schaut sehr genau, dass die Übung korrekt ausgeführt wird und er geht auf Fragen und Wünsche ein! Ich kann ihn auf jeden Fall empfehlen und wünsche euch jetzt schon viel Spass beim Training mit ihm!"
            />
            <Quotation
              img={<Img fixed={gleb} />}
              name="Gleb"
              text="I started training with Ilya about six months ago, and since then, I've built a lot of strengths and endurance. Ilya's technics and variety of exercises helped me to recover my damaged shoulder and achieve desired results."
            />
            <Quotation
              img={<Img fixed={olga} />}
              name="Olga Melnikova"
              text="Ilya is easy-going and positive, and what’s more important he really results-oriented trainer. I started going to the gym only because of the problems with my back, but now I enjoy doing sports while training with Ilya. I improved not only my health and strength but also my shape, which was always very important for me as for the woman. Moreover, Ilya helped me to make the right nutrition plan to stay fit and gain the results I always dreamed about."
            />
          </Slider>
        </StyledContainer>
      </section>
    </Fragment>
  )
}

const Testimonials = () => {
  const images = useStaticQuery(graphql`
    query {
      gleb: file(relativePath: { eq: "gleb.png" }) {
        childImageSharp {
          fixed(width: 70) {
            ...GatsbyImageSharpFixed_noBase64
          }
        }
      }
      olga: file(relativePath: { eq: "olga.png" }) {
        childImageSharp {
          fixed(width: 70) {
            ...GatsbyImageSharpFixed_noBase64
          }
        }
      }
    }
  `)

  return (
    <TestimonialsTemplate
      gleb={images.gleb.childImageSharp.fixed}
      olga={images.olga.childImageSharp.fixed}
    />
  )
}

export default Testimonials
