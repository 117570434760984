import mapboxgl from 'mapbox-gl';
import React, { Component } from 'react';
import styled from 'styled-components';

const fitnessCoordinates = {
  lng: 8.53390980291613,
  lat: 47.3643451697453,
};

const markerURL = '/pin-50x50.png';

const StyledMap = styled.div`
  height: 100%;
  width: 100%;
`;

const StyledMapContainer = styled.div`
  height: 35em;
  overflow: hidden;
  width: 100%;
`;

export default class Map extends Component {
  state = {
    lng: fitnessCoordinates.lng,
    lat: fitnessCoordinates.lat,
    zoom: 15,
  };
  componentDidMount() {
    mapboxgl.accessToken = process.env.GATSBY_MAP_KEY;
    const map = new mapboxgl.Map({
      container: this.mapContainer,
      style: 'mapbox://styles/cookiehunter/ck36eh5xn0qmw1cmjx4x9htz9',
      center: [this.state.lng, this.state.lat],
      zoom: this.state.zoom,
    });

    map.on('move', () => {
      this.setState({
        lng: map.getCenter().lng.toFixed(4),
        lat: map.getCenter().lat.toFixed(4),
        zoom: map.getZoom().toFixed(2),
      });
    });

    map.on('load', function() {
      /* Image: An image is loaded and added to the map. */
      map.loadImage(markerURL, function(error, image) {
        if (error) throw error;
        map.resize();
        map.addImage('custom-marker', image);
        /* Style layer: A style layer ties together the source and image and specifies how they are displayed on the map. */
        map.addLayer({
          id: 'markers',
          type: 'symbol',
          /* Source: A data source specifies the geographic coordinate where the image marker gets placed. */
          source: {
            type: 'geojson',
            data: {
              type: 'FeatureCollection',
              features: [
                {
                  type: 'Feature',
                  properties: {},
                  geometry: {
                    type: 'Point',
                    coordinates: [fitnessCoordinates.lng, fitnessCoordinates.lat],
                  },
                },
              ],
            },
          },
          layout: {
            'icon-image': 'custom-marker',
          },
        });
      });
    });
  }

  render() {
    return (
      <StyledMapContainer>
        <StyledMap ref={(el) => (this.mapContainer = el)} />;
      </StyledMapContainer>
    );
  }
}
