import React from 'react';
import styled from 'styled-components';

const Grid = styled.div`
  display: grid;
  position: relative;
  padding: 0 1em;
  grid-template-columns: auto auto auto;
  grid-template-rows: 4em auto 3em;
  grid-template-areas:
    'left-quote . avatar'
    '. text .'
    '. name right-quote';
`;

const Text = styled.p`
  text-align: center;
  grid-area: text;
  padding-top: 10px;
`;

const Name = styled.p`
  padding-top: 1em;
  padding-right: 2em;
  text-align: center;
  grid-area: name;
  text-align: right;
  font-weight: 300;
  margin: 0;
`;

const QuotationMark = styled.h1`
  grid-area: ${({ area }) => area};
  color: black;
`;

const Avatar = styled.span`
  position: absolute;
  width: 4em;
  top: 0px;
  z-index: 10;
  right: 10px;
`;

const Quotation = ({ img, name, text }) => {
  return (
    <Grid>
      <QuotationMark className="has-text-dark is-family-secondary is-size-2" area="left-quote">
        &#8220;
      </QuotationMark>
      <QuotationMark className="has-text-dark is-family-secondary is-size-2" area="right-quote">
        &#8221;
      </QuotationMark>
      <Text>{text}</Text>
      <Name className="has-size-3">{name}</Name>
      <Avatar>{img}</Avatar>
    </Grid>
  );
};

export default Quotation;
