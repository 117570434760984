import React from 'react';
import styled from 'styled-components';

import SocialsIcons from './SocialIcons';

const StyledNavbar = styled.nav`
  display: flex;
  justify-content: right;
  justify-content: flex-end;
  .navbar-item {
    margin-left: 0;
  }
`;

const SocialsNavbar = () => {
  return (
    <StyledNavbar id="socialNavbar" role="navigation" aria-label="main navigation">
      <SocialsIcons />
    </StyledNavbar>
  );
};

export default SocialsNavbar;
