import React from 'react';
import styled from 'styled-components';

const Icon = styled.span`
  font-size: 1em;
  margin-right: 0.8em;
`;

const Item = styled.div`
  margin-bottom: 0.8em;
`;
const OfferCardItem = ({ text }) => {
  return (
    <Item>
      <Icon className="icon icon-confirm"></Icon>
      <span className="has-text-weight-bold">{text}</span>
    </Item>
  );
};

export default OfferCardItem;
