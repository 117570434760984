import React, { useContext, useEffect, useRef } from "react"
import styled from "styled-components"

import SocialsIcons from "../components/SocialIcons"
import { BLUE } from "../constants/style"
import { AppContext } from "../contexts/appContext"

const OverlayContainer = styled.div`
  position: fixed;
  top: 20%;
  width: 100%;
  height: 20em;
  z-index: 100;
  margin: 0 auto;
`

const Content = styled.div`
  max-width: 30em;
  background-color: ${BLUE};
  padding: 2em 0.5em;
  -webkit-box-shadow: 6px 7px 23px 0px rgba(108, 162, 255, 0.8);
  -moz-box-shadow: 6px 7px 23px 0px rgba(108, 162, 255, 0.8);
  box-shadow: 6px 7px 23px 0px rgba(108, 162, 255, 0.8);
`

const Icons = styled.div`
  display: flex;
  justify-content: space-evenly;
  font-size: 1.5em;
`

function useOutsideAlerter(ref) {
  const { setOverlay } = useContext(AppContext)

  function handleClickOutside(event) {
    if (ref.current && !ref.current.contains(event.target)) {
      setOverlay(false)
    }
  }

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside)
    return () => {
      document.removeEventListener("mousedown", handleClickOutside)
    }
  })
}

const Overlay = () => {
  const wrapperRef = useRef(null)
  const { overlay } = useContext(AppContext)
  useOutsideAlerter(wrapperRef)

  return overlay ? (
    <OverlayContainer>
      <Content className="container" ref={wrapperRef}>
        <Icons>
          <SocialsIcons />
        </Icons>
      </Content>
    </OverlayContainer>
  ) : null
}

export default Overlay
