import { graphql, useStaticQuery } from 'gatsby';
import BackgroundImage from 'gatsby-background-image';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';

import Title from '../elements/Title';
import Motto, { MottoTemplate } from './Motto';

const StyledPortraitImage = styled(BackgroundImage)`
  min-height: 24em;
  height: 100%;
`;

const StyledGroupImage = styled(BackgroundImage)`
  height: 100%;
`;

const TextContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  height: 100%;
`;

const StyledTitle = styled.span`
  width: 100%;
  align-self: flex-start;
  margin-bottom: 2em;
`;

const StyledCard = styled.div`
  margin: 3em auto;
  .notification {
    padding: 1.25rem 1.5rem 1.25rem 1.5rem;
  }
`;

export const AboutCardTemplate = ({ portrait, group, children }) => (
  <React.Fragment>
    <section id="About" className="section is-small">
      <div>
        <h1 className="is-size-3 has-text-left shifted">
          <Title>
            <FormattedMessage id="About.title" />
          </Title>
        </h1>
      </div>
    </section>
    <StyledCard className="container">
      <div className="notification">
        <div className="columns">
          <div className="column">
            <StyledPortraitImage fluid={portrait} tag="div" />
          </div>
          <div className="column has-text-justified">
            <TextContainer>
              <StyledTitle>
                <h4 className="title is-4">
                  <FormattedMessage id="About.p1.title" />
                </h4>
                <h5 className="subtitle is-5 has-text-grey">
                  <FormattedMessage id="About.p1.subtitle" />
                </h5>
              </StyledTitle>
              <p>
                <FormattedMessage id="About.p1" />
              </p>
            </TextContainer>
          </div>
        </div>
      </div>
    </StyledCard>

    {children || <MottoTemplate />}

    <StyledCard className="container">
      <div className="notification">
        <div className="columns is-multiline">
          <div className="column has-text-justified">
            <TextContainer>
              <StyledTitle>
                <h4 className="title is-4">
                  <FormattedMessage id="About.p2.title" />
                </h4>
                <h5 className="subtitle is-5 has-text-grey">
                  <FormattedMessage id="About.p2.subtitle" />
                </h5>
              </StyledTitle>
              <p>
                <FormattedMessage id="About.p2" />
              </p>
            </TextContainer>
          </div>
          <div className="column is-full-tablet is-full-desktop is-half-fullhd">
            <StyledGroupImage fluid={group} className="horizontal-photo" />
          </div>
        </div>
      </div>
    </StyledCard>
  </React.Fragment>
);

const AboutCard = () => {
  const images = useStaticQuery(graphql`
    query {
      portrait: file(relativePath: { eq: "about_portrait.jpg" }) {
        childImageSharp {
          fluid(quality: 90, maxWidth: 600) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      group: file(relativePath: { eq: "about_group.jpg" }) {
        childImageSharp {
          fluid(quality: 90, maxWidth: 600) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
    }
  `);
  return (
    <AboutCardTemplate portrait={images.portrait.childImageSharp.fluid} group={images.group.childImageSharp.fluid}>
      <Motto />
    </AboutCardTemplate>
  );
};

export default AboutCard;
