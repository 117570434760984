import React from 'react';
import { FormattedMessage } from 'react-intl';

import Tile from '../elements/Tile';

const Offers = () => {
  return (
    <div className="section">
      <div className="columns is-centered">
        <div className="column is-12-desktop is-8-widescreen">
          <div className="tile is-ancestor">
            <div className="tile is-parent has-text-centered">
              <div className="tile is-child box is-shadowless">
                <Tile
                  label={<FormattedMessage id="Tile1.label" />}
                  icon="fi flaticon-scales"
                  text={<FormattedMessage id="Tile1.text" />}
                />
              </div>
              <div className="tile is-child box is-shadowless">
                <Tile
                  label={<FormattedMessage id="Tile2.label" />}
                  icon="fi flaticon-dumbbell"
                  text={<FormattedMessage id="Tile2.text" />}
                />
              </div>
              <div className="tile is-child box is-shadowless">
                <Tile
                  label={<FormattedMessage id="Tile4.label" />}
                  icon="fi flaticon-list"
                  text={<FormattedMessage id="Tile4.text" />}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Offers;
