import { graphql, useStaticQuery } from "gatsby"
import BackgroundImage from "gatsby-background-image"
import React from "react"
import { FormattedMessage } from "react-intl"
import styled from "styled-components"

import { BLUE } from "../constants/style"
import Button from "../elements/Button"
import Navbar from "./Navbar"
import SocialsNavbar from "./SocialsNavbar"
import Img from "gatsby-image"

const StyledContainer = styled.div`
  min-height: 18em;
  .button.is-primary {
    margin-top: 7em;
  }
`

const StyledBackground = styled(BackgroundImage)`
  background-attachment: fixed;
`

const HeroContent = () => (
  <StyledContainer className="container">
    <h1 className="is-size-4 is-size-3-tablet has-text-dark">
      <mark className="mark">
        <FormattedMessage id="HERO.titleFirstLine" />
      </mark>
    </h1>
    <h1 className="is-size-4 is-size-3-tablet has-text-dark">
      <mark className="mark">
        <FormattedMessage id="HERO.titleSecondLine" />
      </mark>
    </h1>
    <Button className="button is-primary is-outlined has-text-white is-medium is-radiusless is-family-secondary is-uppercase">
      <FormattedMessage id="HERO.button" />
    </Button>
  </StyledContainer>
)

export const HeroTemplate = ({ background }) => (
  <section id="hero" className="hero is-fullheight">
    <Navbar className="hero-head" />
    {background ? (
      <StyledBackground
        className="hero-body"
        Tag="div"
        fluid={background}
        backgroundColor={BLUE}
      >
        <HeroContent />
      </StyledBackground>
    ) : (
      <HeroContent />
    )}

    <SocialsNavbar className="hero-foot" />
  </section>
)

const Hero = () => {
  const images = useStaticQuery(graphql`
    query {
      background: file(relativePath: { eq: "hero.jpg" }) {
        childImageSharp {
          fluid(quality: 90, maxWidth: 1920) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
    }
  `)
  return (
    <HeroTemplate
      background={images.background.childImageSharp.fluid}
    />
  )
}

export default Hero
