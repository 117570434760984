import { graphql, useStaticQuery } from 'gatsby';
import BackgroundImage from 'gatsby-background-image';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';

const StyledBackground = styled(BackgroundImage)`
  background-position: 5em;
`;

export const MottoTemplate = ({ background }) => (
  <section className="hero is-primary is-medium">
    <StyledBackground id="motto-background" className="hero-body" Tag="div" fluid={background}>
      <div className="container">
        <h1 className="title is-family-secondary shifted">
          <FormattedMessage id="MyMotto.prefix" />
        </h1>
        <h2 className="subtitle">
          <FormattedMessage id="MyMotto.body" />
        </h2>
      </div>
    </StyledBackground>
  </section>
);

const Motto = () => {
  const images = useStaticQuery(graphql`
    query {
      background: file(relativePath: { eq: "motto.png" }) {
        childImageSharp {
          fluid(quality: 90, maxWidth: 1920) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
    }
  `);
  return <MottoTemplate background={images.background.childImageSharp.fluid} />;
};

export default Motto;
