import "../styles/index.scss"

import React from "react"
import { IntlProvider } from "react-intl"

import AboutCard, { AboutCardTemplate } from "../components/AboutCard"
import Contacts, { ContactsTemplate } from "../components/Contacts"
import Footer from "../components/Footer"
import Head from "../components/Head"
import Hero, { HeroTemplate } from "../components/Hero"
import I18n from "../components/I18n"
import Intro from "../components/Intro"
import OfferCards from "../components/OfferCards"
import Offers from "../components/Offers"
import Testimonials from "../components/Testimonials"
import Overlay from "../elements/Overlay"
import { AppContextProvider } from "../contexts/appContext"

export const MainPageTemplate = ({ messages, locale }) => {
  return (
    <IntlProvider locale={locale} messages={messages}>
      <AppContextProvider locale={locale} pagePath={""}>
        <Overlay />
        <Head />
        <HeroTemplate />
        <Intro />
        <Offers />
        <OfferCards />
        <AboutCardTemplate />
        <ContactsTemplate />
        <Footer />
      </AppContextProvider>
    </IntlProvider>
  )
}

export default ({ pageContext }) => (
  <I18n locale={pageContext.locale} page="/">
    <AppContextProvider locale={pageContext.locale} pagePath={""}>
      <Overlay />
      <Head />
      <Hero />
      <Intro />
      <Offers />
      <OfferCards />
      <AboutCard />
      <Testimonials />
      <Contacts />
      <Footer />
    </AppContextProvider>
  </I18n>
)
