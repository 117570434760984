import React from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';

import { BLUE, WHITE } from '../constants/style';
import Button from '../elements/Button';
import Map from './Map';

const InfoContainer = styled.span`
  top: 10%;
  left: 5%;
  z-index: 10;
  margin: 0 auto;
  position: absolute;
`;

const Content = styled.div`
  max-width: 30em;
  background-color: ${BLUE};
  padding: 1em 1.7em 1em 1em;
  -webkit-box-shadow: 6px 7px 23px 0px rgba(108, 162, 255, 0.8);
  -moz-box-shadow: 6px 7px 23px 0px rgba(108, 162, 255, 0.8);
  box-shadow: 6px 7px 23px 0px rgba(108, 162, 255, 0.8);
`;

const StyledButton = styled(Button)`
  margin-top: -5em;
  background-color: ${WHITE} !important;
  z-index: 99;
  &:hover {
    background-color: ${BLUE} !important;
    cursor: pointer;
  }
`;

const MapPlaceholder = styled.div`
  height: 35em;
  width: 100%;
`;

export const ContactsTemplate = ({ children }) => (
  <section className="hero is-relative">
    <InfoContainer>
      <Content className="container has-text-white is-size-6">
        <p className="is-size-5">Ultimate Studio</p>
        <p>Jenatschstrasse 3</p>
        <p>8002 Zürich</p>
      </Content>
    </InfoContainer>
    {children || <MapPlaceholder />}
    <div className="container has-text-centered">
      <StyledButton className="button is-primary is-outlined is-medium is-radiusless is-family-secondary is-uppercase">
        <FormattedMessage id="Contacts.button" />
      </StyledButton>
    </div>
  </section>
);

const Contacts = () => {
  return (
    <ContactsTemplate>
      <Map />
    </ContactsTemplate>
  );
};

export default Contacts;
